import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["NotificationComponent"]),
    ($setup.isAr)
      ? (_openBlock(), _createBlock($setup["ArView"], {
          key: 0,
          onBack: $setup.back,
          class: _normalizeClass({ hidden: $setup.isHidden, show: $setup.isShow })
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({ ar: $setup.isAr, back: $setup.isBack })
    }, [
      _createVNode(_component_router_view),
      ($setup.shouldShowArButton)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["position", { 'not-loading': !$setup.isLoader($setup.LoaderKeys.FirstLoad) }])
          }, [
            _createVNode($setup["ArButton"], {
              onClick: $setup.openArView,
              onBack: $setup.back
            })
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}