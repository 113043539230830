import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass({ disabled: $props.isLoaded || $props.isDisabled, loaded: $props.isLoaded })
  }, [
    (!$props.isLoaded)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
      : _createCommentVNode("", true),
    ($props.isLoaded)
      ? (_openBlock(), _createBlock($setup["CommonLoader"], {
          key: 1,
          class: "small"
        }))
      : _createCommentVNode("", true)
  ], 2))
}