import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Landing",
    component: () =>
      import(/* webpackChunkName: "landing" */ "../views/LandingView.vue"),
    meta: {
      showAr: true,
    },
  },
  {
    path: "/profile/",
    name: "Profile",
    redirect: { path: "/profile/details" },
    component: () =>
      import(
        /* webpackChunkName: "Profile" */ "../views/profile/ProfilePage.vue"
      ),
    meta: {
      showAr: true,
    },
    children: [
      {
        path: "favourite",
        name: "Favourite",
        component: () =>
          import(
            /* webpackChunkName: "ProfileFavourites" */ "../views/profile/ProfileFavourites.vue"
          ),
      },
      {
        path: "details",
        name: "Details",
        component: () =>
          import(
            /* webpackChunkName: "ProfileDetails" */ "../views/profile/ProfileDetails.vue"
          ),
      },
      {
        path: "collection",
        name: "My Collection",
        component: () =>
          import(
            /* webpackChunkName: "ProfilePrints" */ "../views/profile/ProfileMyCollection.vue"
          ),
      },
      {
        path: "pairs",
        name: "AR pairs",
        component: () =>
          import(
            /* webpackChunkName: "ProfileARPairs" */ "../views/profile/ProfileARPairs.vue"
          ),
      },
    ],
  },
  {
    path: "/redactor",
    name: "Redactor",
    component: () =>
      import(/* webpackChunkName: "redactor" */ "../views/RedactorView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/LoginView.vue"),
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: () =>
      import(
        /* webpackChunkName: "sign-up" */ "../views/auth/RegistrationView.vue"
      ),
  },
  {
    path: "/vr",
    name: "VR",
    component: () =>
      import(/* webpackChunkName: "vr" */ "../views/ModelView.vue"),
  },
];

export default routes;
