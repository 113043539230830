import { createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52345d0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ar" }
const _hoisted_2 = {
  key: 0,
  class: "content"
}
const _hoisted_3 = {
  key: 1,
  class: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_entity = _resolveComponent("a-entity")!
  const _component_a_marker = _resolveComponent("a-marker")!
  const _component_a_scene = _resolveComponent("a-scene")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["HeaderComponent"], { onBack: $setup.goBack }),
    _createVNode($setup["CommonButton"], {
      class: "btn_icon btn_secondary ar-back",
      onClick: $setup.goBack
    }, {
      default: _withCtx(() => [
        _createTextVNode("Back")
      ]),
      _: 1
    }),
    ($setup.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_a_scene, {
            embedded: "",
            arjs: "",
            "vr-mode-ui": "false"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.combinations, (item) => {
                return (_openBlock(), _createBlock(_component_a_marker, {
                  key: item.id,
                  type: "pattern",
                  url: `${$setup.storageUrl}${item.attributes.marker.data.attributes.pattern.data.attributes.url}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_entity, {
                      position: "0 0 0",
                      scale: "0.01 0.01 0.01",
                      "gltf-model": `${$setup.storageUrl}${item.attributes.model.data.attributes.source.data.attributes.url}`
                    }, null, 8, ["gltf-model"])
                  ]),
                  _: 2
                }, 1032, ["url"]))
              }), 128)),
              _createVNode(_component_a_entity, { camera: "" })
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3))
  ]))
}