import { ref } from "vue";
interface keyString {
  [key: string]: boolean;
}

export enum LoaderKeys {
  FirstLoad = "first-load",
  SaveBtn = "save-btn",
  List = "list",
}

export const loaderKeys = ref<keyString>({});
export const currentKey = ref();

export function startLoader(key: string) {
  currentKey.value = key;
  loaderKeys.value[currentKey.value] = true;
}

export function finishLoader(key: string) {
  currentKey.value = key;
  loaderKeys.value[currentKey.value] = false;
}

export function isLoader(key: string) {
  return loaderKeys.value[key];
}
