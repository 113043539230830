import { ApiService } from "@/services/api";
import { ArCombinationData, DefaultStrapi } from "@/types/requests/prints";
import { CommonResponse } from "@/types/requests/common";

export const getArCombination = (query?: string) => {
  return ApiService.get<CommonResponse<DefaultStrapi<ArCombinationData>>>(
    `ar-combinations${query}`
  );
};

export const updateArCombination = (
  combinationId: number,
  data: { model?: number; marker?: number }
) => {
  return ApiService.put<CommonResponse<DefaultStrapi<ArCombinationData>>>(
    `ar-combinations/${combinationId}?populate=*`,
    {
      data: data,
    }
  );
};
export const deleteCombination = (combinationId: number) => {
  return ApiService.delete(`ar-combinations/${combinationId}`);
};
