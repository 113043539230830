import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22296689"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header--left" }
const _hoisted_3 = {
  key: 0,
  class: "header--right"
}
const _hoisted_4 = {
  key: 1,
  class: "header--right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: _normalizeClass(["header--left-logo", $props.classColor]),
        href: "/"
      }, null, 2)
    ]),
    ($setup.userStore.isAuthorized)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode($setup["UserPopup"], {
            class: _normalizeClass(["profile", $props.classColor])
          }, null, 8, ["class"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: "/login",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('back')))
          }, {
            default: _withCtx(() => [
              _createVNode($setup["CommonButton"], { class: "btn_default btn_round" }, {
                default: _withCtx(() => [
                  _createTextVNode("Log in")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/sign-up",
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('back')))
          }, {
            default: _withCtx(() => [
              _createVNode($setup["CommonButton"], { class: "btn_secondary btn_round" }, {
                default: _withCtx(() => [
                  _createTextVNode("Sign up")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
  ]))
}