import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd02d2dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar-block" }
const _hoisted_2 = {
  key: 0,
  class: "user-avatar--box"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "letter-avatar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.userStore.user.image)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "user-avatar",
            src: $setup.userStore.user.image,
            alt: 'profile_img'
          }, null, 8, _hoisted_3)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.logoName), 1))
  ]))
}