import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fbce344"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (item) => {
      return _createElementVNode("div", {
        class: _normalizeClass(["dot", { animate: $setup.animateArr.includes(item) }])
      }, null, 2)
    }), 64))
  ]))
}