import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { UserInterface } from "@/types/user.interface";
export const defaultUser = {
  id: 0,
  username: "",
  email: "",
};
export const useUserStore = defineStore(
  "user",
  () => {
    const data = ref({});
    const user = ref({ ...defaultUser } as UserInterface);
    const redactorImage = ref() as any;
    const selectedImage = ref("");
    const accessToken = ref("");
    const refreshToken = ref();
    const rememberMe = ref(false);

    const isAuthorized = computed(() => !!user.value.id && !!accessToken.value);

    const login = (data: UserInterface) => {
      user.value = data;
    };

    const logout = () => {
      clear();
    };

    const setTokens = (payload: {
      accessToken: string;
      refreshToken?: string;
      rememberMe?: boolean;
    }) => {
      refreshToken.value = payload.refreshToken;
      accessToken.value = payload.accessToken;
      if (typeof payload.rememberMe === "boolean") {
        rememberMe.value = payload.rememberMe;
      }
    };

    const clear = () => {
      data.value = {};
      user.value = { ...defaultUser };
      accessToken.value = "";
      refreshToken.value = "";
      rememberMe.value = false;
    };

    return {
      data,
      user,
      isAuthorized,
      accessToken,
      refreshToken,
      rememberMe,
      setTokens,
      clear,
      login,
      logout,
      selectedImage,
      redactorImage,
    };
  },
  {
    persist: true,
  }
);
