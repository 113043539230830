import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6656ef1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification-block" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, {
      name: "block",
      tag: "div"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.notifications, (notification, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["notification", notification.type]),
            key: notification.key
          }, [
            _createElementVNode("p", null, _toDisplayString(notification.text), 1),
            _createElementVNode("button", {
              onClick: ($event: any) => ($setup.removeNotification(notification.key))
            }, null, 8, _hoisted_2)
          ], 2))
        }), 128))
      ]),
      _: 1
    })
  ]))
}